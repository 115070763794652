<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="newTodo"
            >
              <feather-icon icon="PlusIcon" size="14" />
              <span class="ml-25 align-middle">Nova tarefa</span>
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="filter in toDoFilters"
                :key="filter.type"
                :active="isFilterActive(filter.type)"
                @click="changeFilter(filter.type)"
              >
                <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1" style="cursor: pointer;">{{
                  filter.title
                }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BButton, BListGroup, BListGroupItem, BBadge } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import * as taskTypes from "@/constants/todo_types";
import * as types from "../store/types";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    search: Function,
  },
  data() {
    return {
      // UI
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
      activeFilter: taskTypes.PENDING_TASKS,
      // Filter & Labels
      toDoFilters: [
        {
          title: "Tarefas pendentes",
          icon: "CheckSquareIcon",
          type: taskTypes.PENDING_TASKS,
        },
        {
          title: "Todas as tarefas",
          icon: "AlignJustifyIcon",
          type: taskTypes.ALL_TASKS,
        },
        {
          title: "Importantes",
          icon: "StarIcon",
          type: taskTypes.IMPORTANT_TASKS,
        },
        {
          title: "Concluídas",
          icon: "CheckCircleIcon",
          type: taskTypes.COMPLETED_TASKS,
        },
        { title: "Atrasadas", icon: "ClockIcon", type: taskTypes.LATE_TASKS },
      ],
    };
  },
  methods: {
    ...mapActions({
      openNewToDoSidebar: types.OPEN_NEW_TO_DO_SIDEBAR,
    }),
    changeFilter(type) {
      this.activeFilter = type;
      this.search(1);
      this.$emit("close-left-sidebar");
    },
    isFilterActive(type) {
      return this.activeFilter === type;
    },
    newTodo() {
      this.openNewToDoSidebar({
        id: undefined,
        saveAction: this.search,
      });
    },
  },
};
</script>
