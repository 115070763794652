<template>
  <div>
    <new-to-do-sidebar />
    <to-do-details-sidebar />
    <div class="content-area-wrapper">
      <to-do-inbox-left-sidebar
        ref="todoLeftSidebar"
        :search="search"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
      <div class="content-right">
        <div class="content-wrapper">
          <div class="content-body">
            <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
            <div style="height: inherit">
              <!-- Email List -->
              <div class="email-app-list">
                <!-- App Searchbar Header -->
                <div class="app-fixed-search-2 align-items-center">
                  <!-- Toggler -->
                  <div class="sidebar-toggle d-block d-lg-none ml-1">
                    <feather-icon
                      icon="MenuIcon"
                      size="21"
                      class="cursor-pointer"
                      @click="mqShallShowLeftSidebar = true"
                    />
                  </div>
                  <div class="p-1">
                    <b-row>
                      <b-col cols="12" md="3">
                        <b-form-group
                          label="Procurar tarefa"
                          label-for="searchRangeDate"
                        >
                          <b-form-input
                            v-model="searchQuery"
                            @input="search(1)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="3">
                        <b-form-group
                          label="Período"
                          label-for="searchRangeDate"
                        >
                          <flat-pickr
                            id="searchRangeDate"
                            v-model="rangeDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
                            @input="search(1)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <vue-perfect-scrollbar
                  :settings="perfectScrollbarSettings"
                  class="email-user-list scroll-area"
                >
                  <template v-if="loading">
                    <div class="d-flex justify-content-center m-2">
                      <b-spinner variant="primary" />
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="items.length > 0">
                      <ul class="email-media-list">
                        <b-media
                          v-for="todo in items"
                          tag="li"
                          :key="todo.id"
                          no-body
                          style="height: 60px; cursor: default"
                          :class="{ 'mail-read': todo.is_completed }"
                          @click="openDetails(todo.id)"
                        >
                          <b-media-aside class="media-left mr-50">
                            <div class="user-action">
                              <template v-if="!todo.is_completed">
                                <div
                                  :id="`check-inbox-task-div-${todo.id}`"
                                  class="custom-control custom-checkbox"
                                  @click.stop=""
                                >
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    :id="`check-inbox-task-${todo.id}`"
                                    @change="checkTask(todo.id)"
                                  />
                                  <label
                                    class="custom-control-label"
                                    :for="`check-inbox-task-${todo.id}`"
                                    style="cursor: pointer !important"
                                  ></label>
                                </div>
                                <b-tooltip
                                  v-if="!todo.is_completed"
                                  triggers="hover"
                                  :target="`check-inbox-task-div-${todo.id}`"
                                  title="Marcar como concluída"
                                />
                              </template>
                              <div class="email-favorite">
                                <feather-icon
                                  icon="StarIcon"
                                  v-if="todo.is_important"
                                  size="17"
                                  class="text-warning fill-current"
                                  v-b-tooltip.hover
                                  title="Importante"
                                />
                              </div>
                            </div>
                          </b-media-aside>
                          <b-media-body>
                            <div class="mail-details">
                              <div class="mail-items">
                                <h5
                                  class="mb-25"
                                  v-html="truncateString(todo.title, 75)"
                                ></h5>
                              </div>
                              <div class="mail-meta-item">
                                <span class="mail-date mr-50">
                                  {{
                                    todo.date_start
                                      | todoDateToLiteralFormat(
                                        "short",
                                        todo.is_full_day
                                      )
                                  }}
                                </span>
                                <b-dropdown
                                  variant="link"
                                  no-caret
                                  right
                                  boundary="window"
                                >
                                  <template #button-content>
                                    <feather-icon
                                      icon="MoreVerticalIcon"
                                      size="20"
                                      class="align-middle text-body"
                                    />
                                  </template>
                                  <b-dropdown-item
                                    @click.native.stop="editTodo(todo)"
                                  >
                                    Editar
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                    v-if="!todo.is_important"
                                    @click.native.stop="
                                      markTaskImportant(todo.id)
                                    "
                                  >
                                    Marcar importante
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                    @click.native.stop="removeTodo(todo)"
                                  >
                                    Remover
                                  </b-dropdown-item>
                                </b-dropdown>
                              </div>
                            </div>
                          </b-media-body>
                        </b-media>
                      </ul>
                      <div class="m-2" v-if="totalItems > 20">
                        <b-row>
                          <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                          >
                            <span class="text-muted"
                              >Exibindo {{ pageItemsFrom }} à
                              {{ pageItemsTo }} de {{ totalItems }} itens</span
                            >
                          </b-col>
                          <!-- Pagination -->
                          <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                          >
                            <b-pagination
                              :value="currentPage"
                              :total-rows="totalItems"
                              :per-page="itemsPerPage"
                              first-number
                              last-number
                              class="mb-0 mt-1 mt-sm-0"
                              prev-class="prev-item"
                              next-class="next-item"
                              @change="search"
                            >
                              <template #prev-text>
                                <feather-icon
                                  icon="ChevronLeftIcon"
                                  size="18"
                                />
                              </template>
                              <template #next-text>
                                <feather-icon
                                  icon="ChevronRightIcon"
                                  size="18"
                                />
                              </template>
                            </b-pagination>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                  </template>
                  <div
                    class="no-results"
                    :class="{ show: !loading && !items.length }"
                  >
                    <h5>Nenhuma tarefa encontrada</h5>
                  </div>
                </vue-perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BCol,
  BFormGroup,
  BRow,
  BButton,
  BSpinner,
  BTooltip,
  BPagination,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import { filterTags, formatDateToMonthShort } from "@core/utils/filter";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ToDoInboxLeftSidebar from "./ToDoInboxLeftSidebar.vue";
import ToDoDetailsSidebar from "../components/ToDoDetailsSidebar.vue";
import NewToDoSidebar from "../components/NewToDoSidebar.vue";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { getRangeDates } from "@/helpers/date_picker";
import { truncateString } from "@/helpers/converters";
import * as types from "../store/types";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormGroup,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BTooltip,
    NewToDoSidebar,
    BPagination,
    VuePerfectScrollbar,
    ToDoInboxLeftSidebar,
    flatPickr,
    ToDoDetailsSidebar,
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const actualYear = new Date().getFullYear();
    return {
      mqShallShowLeftSidebar,
      toast: useToast(),
      actualYear
    };
  },
  data() {
    return {
      loading: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      searchQuery: "",
      rangeDate: undefined,
      currentPage: 1,
      itemsPerPage: 20,
    };
  },
  computed: {
    ...mapGetters({
      todos: types.TODOS_INBOX_SEARCH_RESULT,
    }),
    items: function () {
      return this.todos?.data || [];
    },
    totalItems: function () {
      return this.todos?.total || 0;
    },
    pageItemsFrom: function () {
      return this.todos?.from || 0;
    },
    pageItemsTo: function () {
      return this.todos?.to || 0;
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    ...mapMutations({
      mutateInboxSearch: types.MUTATE_TODOS_INBOX_SEARCH_RESULT,
    }),
    ...mapActions({
      searchTodos: types.SEARCH_TODOS_INBOX,
      openNewToDoSidebar: types.OPEN_NEW_TO_DO_SIDEBAR,
      markAsCompleted: types.MARK_AS_COMPLETED,
      deleteTask: types.DELETE_TASK,
      markAsImportant: types.MARK_AS_IMPORTANT,
      openToDoDetailsSidebar: types.OPEN_TO_DO_DETAILS_SIDEBAR,
    }),
    formatDateToMonthShort,
    truncateString,
    checkTask(taskId) {
      this.markAsCompleted(taskId).then((response) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Sucesso",
            text: "A operação foi concluida com sucesso!",
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
        this.search();
      });
    },
    editTodo(task) {
      this.openNewToDoSidebar({
        id: task.id,
        saveAction: this.search,
      });
    },
    removeTodo(task) {
      if (task.task_recurrence_id > 0) {
        this.openNewToDoSidebar({
          id: task.id,
          isRemove: true,
          saveAction: this.search,
        });
      } else {
        this.$swal({
          title: "Confirmação",
          text: `Deseja realmente remover? A operação não poderá ser desfeita.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Remover",
          cancelButtonText: "Cancelar",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary ml-1",
            htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
          },
        }).then((result) => {
          if (result.value) {
            this.deleteTask({
              id: task.id,
              delete_type: "current_task",
            }).then(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: "A operação foi concluida com sucesso!",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.search();
            });
          }
        });
      }
    },
    markTaskImportant(taskId) {
      this.markAsImportant(taskId).then(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Sucesso",
            text: "A operação foi concluida com sucesso!",
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
        this.search();
      });
    },
    openDetails(taskId) {
      this.openToDoDetailsSidebar({
        id: taskId,
        saveAction: this.search,
      });
    },
    search(currentPage) {
      this.loading = true;
      this.currentPage = Number.isInteger(currentPage)
        ? currentPage
        : this.currentPage;
      const { searchQuery, rangeDate } = this;
      const range_date = getRangeDates(rangeDate);
      this.searchTodos({
        title: searchQuery,
        date_start: range_date.start,
        date_end: range_date.end,
        task_type: [this.$refs.todoLeftSidebar.activeFilter],
        currentPage: this.currentPage,
        limit: this.itemsPerPage,
      })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as tarefas. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
